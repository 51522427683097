;(function($) {

// ---------- APP START
window.App = {

  config: {
    scrollOffset: 150
  },

  init: function() {

    $('a[href^="#"]').on('click', function(e){
      e.preventDefault();
      var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');
      if($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
        App.scroll(scrollTo, $(this).data('scroll-offset'));
    });

    if($('[data-scroll]').length){
      App.scroll($('[data-scroll]').data('scroll'));
    }
		
		$('.js-menu-toggle').on('click touch', function() {
			if ($('body').hasClass('is-nav-open')) {
				$('body').removeClass('is-nav-open');
			} else {
				$('body').addClass('is-nav-open');
			}
		});

		App.initSlider();
		App.initForms();
  },

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;
		$('html, body').scrollTop($(element).offset().top - offset);
  },

	/* Sliders
	----------------------------------*/
	initSlider: function() {
		$('.swiper').each(function(index, element) {
			var $items = parseFloat(element.dataset.items);
			var $space = $items > 1 ? 8 : 0;
			var $nav = element.getElementsByClassName('swiper-pagination');
			new Swiper(element, {
				slidesPerView: $items,
				speed: 400,
				spaceBetween: 8,
				pagination: {
					el: $nav[0],
					clickable: true
				},
				breakpoints: {
					320: {
						slidesPerView: 1
					},
					768: {
						slidesPerView: 2
					},
					1024: {
						slidesPerView: $items,
						spaceBetween: $space
					}
				}
			});
		});
	},

  initForms: function() {
    $('.form [name=token]').val($('meta[name=refresh]').attr('content'));
  },
};
// ---------- APP END

// Init app
$(document).ready(App.init);

})(Zepto);